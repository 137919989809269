var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.isLoadingData || _vm.isSavingGeneralDocument}},[_c('ValidationObserver',{ref:"createNewsLetters",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.save($event)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('ValidationProvider',{attrs:{"name":"hotel","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Hotel*"}},[_c('b-form-select',{attrs:{"state":errors[0] ? false : valid ? true : null},model:{value:(_vm.documentData.resort),callback:function ($$v) {_vm.$set(_vm.documentData, "resort", $$v)},expression:"documentData.resort"}},[_c('option',{attrs:{"disabled":""},domProps:{"value":null}},[_vm._v("Seleccione Hotel")]),_vm._l((_vm.hotels),function(hotel){return _c('option',{key:hotel.id,domProps:{"value":hotel.id}},[_vm._v(" "+_vm._s(hotel.name)+" ")])})],2),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"3"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"nombre"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Nombre*"}},[_c('b-form-input',{staticClass:"form-control",attrs:{"type":"text","state":errors[0] ? false : valid ? true : null},model:{value:(_vm.documentData.name),callback:function ($$v) {_vm.$set(_vm.documentData, "name", $$v)},expression:"documentData.name"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"rules":_vm.documentData.file ? '' : 'required',"name":"archivo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Documento*"}},[_c('b-form-file',{attrs:{"placeholder":_vm.documentData.file ? _vm.documentData.documentName : 'Adjunte un archivo como evidencia...',"drop-placeholder":"Drop file here...","state":errors[0] ? false : valid ? true : null,"browse-text":"Buscar archivo"},on:{"input":function($event){return _vm.setImgFile(_vm.file)}},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))]),(_vm.documentData.documentName)?_c('span',[_vm._v(" Archivo seleccionado: "),_c('b-link',{attrs:{"href":_vm.sourceFile(),"target":"_blank","rel":"noopener noreferrer"}},[_c('strong',[_vm._v(_vm._s(_vm.documentData.documentName))])])],1):_vm._e()],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"3"}},[_c('ValidationProvider',{attrs:{"name":"type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Tipo de documento*"}},[_c('b-form-select',{attrs:{"state":errors[0] ? false : valid ? true : null,"disabled":""},model:{value:(_vm.documentData.type),callback:function ($$v) {_vm.$set(_vm.documentData, "type", $$v)},expression:"documentData.type"}},[_c('option',{attrs:{"disabled":""},domProps:{"value":null}},[_vm._v("Seleccione una opción")]),_vm._l((_vm.ownersDocumentsType),function(type){return _c('option',{key:type.id,domProps:{"value":type.id}},[_vm._v(" "+_vm._s(type.type)+" ")])})],2),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"3"}},[_c('ValidationProvider',{attrs:{"name":"membership","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Membresías*"}},[_c('v-select',{class:("" + (errors.length > 0 ? 'is-invalid border-danger rounded' : '')),attrs:{"multiple":"","label":"code","placeholder":"Seleccione membresías","options":_vm.membershipsOptions,"state":errors[0] ? false : valid ? true : null},on:{"option:deselected":_vm.deleteMembership},model:{value:(_vm.documentData.memberships),callback:function ($$v) {_vm.$set(_vm.documentData, "memberships", $$v)},expression:"documentData.memberships"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"descripcion"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Descripción"}},[_c('b-form-textarea',{staticClass:"form-control",attrs:{"type":"text","state":errors[0] ? false : valid ? true : null},model:{value:(_vm.documentData.description),callback:function ($$v) {_vm.$set(_vm.documentData, "description", $$v)},expression:"documentData.description"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}],null,true)})],1),_c('b-col',{staticClass:"mr-1",attrs:{"md":"1"}},[_c('div',{staticClass:"mb-1"},[_c('b-form-checkbox',{staticClass:"custom-control-success",attrs:{"value":true,"unchecked-value":false,"switch":""},model:{value:(_vm.documentData.status),callback:function ($$v) {_vm.$set(_vm.documentData, "status", $$v)},expression:"documentData.status"}},[_vm._v(" Activo ")])],1)])],1),_c('b-row',[_c('b-col',[_c('div',{staticClass:"float-right"},[_c('b-button',{staticClass:"btn-block",attrs:{"type":"submit","variant":"primary","disabled":invalid || _vm.isSavingGeneralDocument}},[_vm._v(" Guardar ")])],1)])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }