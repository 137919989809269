<template>
    <b-overlay :show="isLoadingData || isSavingGeneralDocument">
        <b-card >
            <div class="head-btn">
                <b-button
                    :to="{ name: 'general-documents'}"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="warning"
                >
                    <feather-icon icon="ArrowLeftIcon" style="color: white" />
                </b-button>
            </div>
            <div v-if="documentData">
                <OwnerDocsForm :documentData="documentData" :isSavingGeneralDocument="isSavingGeneralDocument" @save-document="update" @delete-membership="deleteMembership"/>
            </div>
            <b-alert show variant="danger" class="p-1 text-center" v-else>
                No se encontró información
            </b-alert>
        </b-card>

    </b-overlay>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import Ripple from 'vue-ripple-directive'
import { showAlertMessage } from '@/helpers/helpers'
import OwnerDocsForm from '@/modules/fivesClub/components/catalogs/ownersWebsite/OwnerDocsForm'

export default {
    directives: {Ripple, },

    components: {
        OwnerDocsForm,
    },
    computed: {
        ...mapState('auth', ['user']),
        ...mapState('start', ['hotels','languages']),
    },
    async created() {
        await this.init()
    },
    data(){
        return {
            documentData: null,
            isLoadingData: false,
            isSavingGeneralDocument: false,
            file: null,
            imgUrl: process.env.VUE_APP_IMG_SRC_API,
        }
    },
    methods: {
        ...mapActions('fivesClubCatalogs', ['fetchOwnersGeneralDocs','updateOwnersGeneralDocument', 'deleteOwnersGeneralDocument']),
        ...mapMutations('start', ['setHotels', 'setLanguages']),
        ...mapMutations('fivesClubCatalogs', ['setSocialNetworks']),

        async init(){
            const idDocument = this.$route.params.id
            this.isLoadingData = true

            if( this.isANumber(idDocument) ){
                const document = await this.fetchOwnersGeneralDocs({id: idDocument})
                if (!!document && document != []) {
                    this.documentData = {
                        id: document.id,
                        name: document.name,
                        description: document.description,
                        type: document.type,
                        file: document.fileRoute,
                        status: document.status,
                        resort: document.resort,
                        memberships: document.memberships,
                        documentName: document.documentName
                    }
                }
            }

            this.isLoadingData = false
        },
        isANumber(string){
            return string ? !isNaN( string ) && string > 0 : false
        },
        async update(payload) {
            this.isSavingGeneralDocument = true;

            const response = await this.updateOwnersGeneralDocument(payload); // updateWebProp in backend
            if (response){
                showAlertMessage('Ok: proceso finalizado', 'InfoIcon', 'Se ha actualizado el registro', 'success', 4000, 'bottom-right' )
                this.$router.push({ name: 'general-documents' })
            }

            this.isSavingGeneralDocument = false;
        },
        async deleteMembership(option){
            if(option){
                const payload = {
                    id: this.documentData.id,
                    idMembership: option.id
                }
                const deleted = await this.deleteOwnersGeneralDocument(payload)
                if (!deleted) {
                    showAlertMessage('No se pudo eliminar la membresía', 'InfoIcon', 'Por favor, inténtelo más tarde', 'warning', 4000, 'bottom-right' )
                }
            }
        }
    },
};
</script>

<style scoped>
.head-btn{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
}
</style>
