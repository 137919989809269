<template>
    <b-overlay :show="isLoadingData || isSavingGeneralDocument">
        <ValidationObserver ref="createNewsLetters" v-slot="{ invalid }">
            <b-form @submit.prevent="save">
                <b-row>
                    <b-col md="3">
                        <ValidationProvider name="hotel" rules="required">
                            <b-form-group label="Hotel*" slot-scope="{ valid, errors }">
                                <b-form-select
                                    :state="errors[0] ? false : valid ? true : null"
                                    v-model="documentData.resort"
                                >
                                    <option :value="null" disabled>Seleccione Hotel</option>
                                    <option v-for="hotel in hotels" :key="hotel.id" :value="hotel.id">
                                        {{ hotel.name }}
                                    </option>
                                </b-form-select>
                                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>

                    <b-col md="3">
                        <ValidationProvider rules="required" name="nombre">
                            <b-form-group label="Nombre*" slot-scope="{ valid, errors }">
                                <b-form-input
                                    class="form-control"
                                    type="text"
                                    v-model="documentData.name"
                                    :state="errors[0] ? false : valid ? true : null"
                                >
                                </b-form-input>
                                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>

                    <b-col md="6">
                        <ValidationProvider :rules="documentData.file ? '' : 'required'" name="archivo">
                            <b-form-group
                                label="Documento*"
                                slot-scope="{ valid, errors }"
                            >
                                <b-form-file
                                    v-model="file"
                                    @input="setImgFile(file)"
                                    :placeholder="documentData.file ? documentData.documentName : 'Adjunte un archivo como evidencia...'"
                                    drop-placeholder="Drop file here..."
                                    :state="errors[0] ? false : valid ? true : null"
                                    browse-text="Buscar archivo"
                                />
                                <b-form-invalid-feedback>{{errors[0]}}</b-form-invalid-feedback>
                                <span v-if="documentData.documentName">
                                    Archivo seleccionado: <b-link :href="sourceFile()" target="_blank" rel="noopener noreferrer"><strong>{{ documentData.documentName }}</strong></b-link>
                                </span>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>

                    <b-col md="3">
                        <ValidationProvider name="type" rules="required">
                            <b-form-group label="Tipo de documento*" slot-scope="{ valid, errors }">
                                <b-form-select
                                    :state="errors[0] ? false : valid ? true : null"
                                    v-model="documentData.type"
                                    disabled
                                >
                                    <option :value="null" disabled>Seleccione una opción</option>
                                    <option v-for="type in ownersDocumentsType" :key="type.id" :value="type.id">
                                        {{ type.type }}
                                    </option>
                                </b-form-select>
                                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>

                    <b-col md="3">
                        <ValidationProvider  name="membership" rules="required">
                            <b-form-group label="Membresías*" slot-scope="{ valid, errors }">
                                <v-select
                                    v-model="documentData.memberships"
                                    multiple
                                    label="code"
                                    placeholder="Seleccione membresías"
                                    :class="`${errors.length > 0 ? 'is-invalid border-danger rounded' : ''}`"
                                    :options="membershipsOptions"
                                    @option:deselected="deleteMembership"
                                    :state="errors[0] ? false : valid ? true : null"
                                />
                                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>

                    <b-col md="6">
                        <ValidationProvider rules="required" name="descripcion">
                            <b-form-group label="Descripción" slot-scope="{ valid, errors }">
                                <b-form-textarea
                                    class="form-control"
                                    type="text"
                                    v-model="documentData.description"
                                    :state="errors[0] ? false : valid ? true : null"
                                >
                                </b-form-textarea>
                                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>

                    <b-col md="1" class="mr-1">
                        <div class="mb-1">
                            <b-form-checkbox
                                v-model="documentData.status"
                                :value="true"
                                :unchecked-value="false"
                                switch
                                class="custom-control-success"
                            > Activo
                            </b-form-checkbox>
                        </div>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col>
                    <div class="float-right">
                        <b-button
                            type="submit"
                            class="btn-block"
                            variant="primary"
                            :disabled="invalid || isSavingGeneralDocument"
                        >
                        Guardar
                        </b-button>
                    </div>
                    </b-col>
                </b-row>
            </b-form>
        </ValidationObserver>
    </b-overlay>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { getLocalFilePath } from "@/helpers/helpers";
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select';
export default {
    directives: {Ripple, },

    components: {
        vSelect,
    },
    async created(){
        await this.init()
    },
    props:{
        documentData: {
            type: Object,
            required: true
        },
        isSavingGeneralDocument: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            isLoadingData: false,
            file: null,
        };
    },
    computed: {
        ...mapState("auth", ["user"]),
        ...mapState("start", ["hotels"]),
        ...mapState("fivesClubCatalogs", ["membershipsOptions", "ownersDocumentsType"])
    },
    methods: {
        ...mapMutations("fivesClubCatalogs", ["setNewsLetters"]),
        ...mapActions("fivesClubCatalogs", ['getInitialContentForGeneralDocuments',"deleteOwnersGeneralDocument"]),
        ...mapMutations('start',['setLanguages']),

        async init(){
            this.isLoadingData = true
            await this.getInitialContentForGeneralDocuments({
                memberships: !this.membershipsOptions.length,
                hotels: !this.hotels.length,
                typeDocuments: !this.ownersDocumentsType.length,
            })
            this.isLoadingData = false
        },
        setImgFile(file) {
            if (file) {
                this.documentData.file = file;
                this.documentData.documentName = file.name.replace(/\.[^\/.]+$/, '')
            }
        },
        async save() {
            this.$emit('save-document', this.documentData)
        },
        async deleteMembership(option){
            this.$emit('delete-membership', option)
        },
        sourceFile(){
            if (typeof this.documentData?.file != 'string') return getLocalFilePath(this.documentData.file)
            else return process.env.VUE_APP_BASE_URL_BACKEND + this.documentData?.file
        },
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

</style>
<style scoped>
.head-btn{
    display: flex;
    justify-content: flex-end;
}
</style>
